<template>
  <div class="mt-5">
    <div class="row mb-5">
      <div class="col-12">
        <label class="form-label">
          {{ selectedLang.newDeclarationQuestion1 }}
        </label>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="true"
          :txt="selectedLang.yes"
          group="question1"
          btnId="question1Yes"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.2521 20.252C37.8745 19.6296 37.8745 18.6204 37.2521 17.998C36.6297 17.3756 35.6206 17.3756 34.9982 17.998L37.2521 20.252ZM21.2501 34L20.1231 35.127C20.7455 35.7494 21.7546 35.7494 22.377 35.127L21.2501 34ZM16.0019 26.498C15.3795 25.8756 14.3704 25.8757 13.748 26.4981C13.1256 27.1205 13.1257 28.1296 13.7481 28.752L16.0019 26.498ZM34.9982 17.998L20.1231 32.8731L22.377 35.127L37.2521 20.252L34.9982 17.998ZM13.7481 28.752L20.1231 35.127L22.377 32.8731L16.0019 26.498L13.7481 28.752ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="false"
          :txt="selectedLang.no"
          group="question1"
          btnId="question1No"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.1269 18.1269C35.7493 17.5046 35.7493 16.4954 35.1269 15.873C34.5045 15.2506 33.4954 15.2506 32.873 15.873L35.1269 18.1269ZM15.873 32.873C15.2506 33.4954 15.2506 34.5046 15.873 35.1269C16.4954 35.7493 17.5046 35.7493 18.127 35.1269L15.873 32.873ZM18.127 15.873C17.5046 15.2506 16.4955 15.2506 15.8731 15.873C15.2507 16.4954 15.2507 17.5046 15.8731 18.1269L18.127 15.873ZM32.873 35.1269C33.4954 35.7493 34.5045 35.7493 35.1269 35.1269C35.7493 34.5046 35.7493 33.4954 35.1269 32.873L32.873 35.1269ZM32.873 15.873L15.873 32.873L18.127 35.1269L35.1269 18.1269L32.873 15.873ZM15.8731 18.1269L32.873 35.1269L35.1269 32.873L18.127 15.873L15.8731 18.1269ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
    </div>

    <div class="row mb-5" v-if="showQuestion2">
      <div class="col-12">
        <label class="form-label">
          {{ selectedLang.newDeclarationQuestion2 }}
        </label>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="true"
          :txt="selectedLang.yes"
          group="question2"
          btnId="question2Yes"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.2521 20.252C37.8745 19.6296 37.8745 18.6204 37.2521 17.998C36.6297 17.3756 35.6206 17.3756 34.9982 17.998L37.2521 20.252ZM21.2501 34L20.1231 35.127C20.7455 35.7494 21.7546 35.7494 22.377 35.127L21.2501 34ZM16.0019 26.498C15.3795 25.8756 14.3704 25.8757 13.748 26.4981C13.1256 27.1205 13.1257 28.1296 13.7481 28.752L16.0019 26.498ZM34.9982 17.998L20.1231 32.8731L22.377 35.127L37.2521 20.252L34.9982 17.998ZM13.7481 28.752L20.1231 35.127L22.377 32.8731L16.0019 26.498L13.7481 28.752ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="false"
          :txt="selectedLang.no"
          group="question2"
          btnId="question2No"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.1269 18.1269C35.7493 17.5046 35.7493 16.4954 35.1269 15.873C34.5045 15.2506 33.4954 15.2506 32.873 15.873L35.1269 18.1269ZM15.873 32.873C15.2506 33.4954 15.2506 34.5046 15.873 35.1269C16.4954 35.7493 17.5046 35.7493 18.127 35.1269L15.873 32.873ZM18.127 15.873C17.5046 15.2506 16.4955 15.2506 15.8731 15.873C15.2507 16.4954 15.2507 17.5046 15.8731 18.1269L18.127 15.873ZM32.873 35.1269C33.4954 35.7493 34.5045 35.7493 35.1269 35.1269C35.7493 34.5046 35.7493 33.4954 35.1269 32.873L32.873 35.1269ZM32.873 15.873L15.873 32.873L18.127 35.1269L35.1269 18.1269L32.873 15.873ZM15.8731 18.1269L32.873 35.1269L35.1269 32.873L18.127 15.873L15.8731 18.1269ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
    </div>

    <div class="row mb-5" v-if="showQuestion3">
      <div class="col-12">
        <label class="form-label">
          {{ selectedLang.newDeclarationQuestion3 }}
        </label>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="true"
          :txt="selectedLang.yes"
          group="question3"
          btnId="question3Yes"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.2521 20.252C37.8745 19.6296 37.8745 18.6204 37.2521 17.998C36.6297 17.3756 35.6206 17.3756 34.9982 17.998L37.2521 20.252ZM21.2501 34L20.1231 35.127C20.7455 35.7494 21.7546 35.7494 22.377 35.127L21.2501 34ZM16.0019 26.498C15.3795 25.8756 14.3704 25.8757 13.748 26.4981C13.1256 27.1205 13.1257 28.1296 13.7481 28.752L16.0019 26.498ZM34.9982 17.998L20.1231 32.8731L22.377 35.127L37.2521 20.252L34.9982 17.998ZM13.7481 28.752L20.1231 35.127L22.377 32.8731L16.0019 26.498L13.7481 28.752ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="false"
          :txt="selectedLang.no"
          group="question3"
          btnId="question3No"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.1269 18.1269C35.7493 17.5046 35.7493 16.4954 35.1269 15.873C34.5045 15.2506 33.4954 15.2506 32.873 15.873L35.1269 18.1269ZM15.873 32.873C15.2506 33.4954 15.2506 34.5046 15.873 35.1269C16.4954 35.7493 17.5046 35.7493 18.127 35.1269L15.873 32.873ZM18.127 15.873C17.5046 15.2506 16.4955 15.2506 15.8731 15.873C15.2507 16.4954 15.2507 17.5046 15.8731 18.1269L18.127 15.873ZM32.873 35.1269C33.4954 35.7493 34.5045 35.7493 35.1269 35.1269C35.7493 34.5046 35.7493 33.4954 35.1269 32.873L32.873 35.1269ZM32.873 15.873L15.873 32.873L18.127 35.1269L35.1269 18.1269L32.873 15.873ZM15.8731 18.1269L32.873 35.1269L35.1269 32.873L18.127 15.873L15.8731 18.1269ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
    </div>

    <div class="row mb-5" v-if="showQuestion4">
      <div class="col-12">
        <label class="form-label">
          {{ selectedLang.newDeclarationQuestion4 }}
        </label>
      </div>

      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="true"
          :txt="selectedLang.newDeclarationQuestion4Answer1"
          group="question4"
          btnId="question4Yes"
          :showIcon="false"
        >
        </NovaDeclaracaoBtn>
      </div>

      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="false"
          :txt="selectedLang.newDeclarationQuestion4Answer2"
          group="question4"
          btnId="question4No"
          :showIcon="false"
        >
        </NovaDeclaracaoBtn>
      </div>
    </div>

    <div class="row mb-5" v-if="showLastBtn">
      <div class="col-12">
        <label class="form-label">
          {{ selectedLang.mustfill }}
        </label>
        <div class="col-12">
          <NovaDeclaracaoBtn
            @click="createNewDeclaration"
            :txt="selectedLang.declarationTypeEnum[declarationType]"
            group="group"
            btnId="group"
          >
            <template #icon>
              <span
                ><svg
                  width="51"
                  height="51"
                  viewBox="0 0 51 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.15"
                    d="M40.375 44.625V9.5625H31.875V12.75H19.125V9.5625H10.625V44.625H40.375Z"
                    fill="#007AC4"
                  />
                  <path
                    d="M33.0019 26.627C33.6243 26.0046 33.6243 24.9954 33.0019 24.373C32.3795 23.7507 31.3704 23.7507 30.748 24.373L33.0019 26.627ZM22.9498 34.4251L21.8229 35.552C22.1217 35.8509 22.5271 36.0188 22.9498 36.0188C23.3725 36.0188 23.7779 35.8509 24.0768 35.552L22.9498 34.4251ZM20.252 29.4731C19.6296 28.8507 18.6205 28.8507 17.9981 29.4731C17.3757 30.0955 17.3756 31.1046 17.998 31.727L20.252 29.4731ZM40.375 9.5625H41.9688C41.9688 8.6823 41.2552 7.96875 40.375 7.96875V9.5625ZM40.375 44.625V46.2188C41.2552 46.2188 41.9688 45.5052 41.9688 44.625H40.375ZM10.625 44.625H9.03125C9.03125 45.5052 9.7448 46.2188 10.625 46.2188V44.625ZM10.625 9.5625V7.96875C9.7448 7.96875 9.03125 8.6823 9.03125 9.5625H10.625ZM19.125 6.375V4.78125C18.2448 4.78125 17.5312 5.4948 17.5312 6.375H19.125ZM31.875 6.375H33.4688C33.4688 5.4948 32.7552 4.78125 31.875 4.78125V6.375ZM31.875 12.75V14.3438C32.7552 14.3438 33.4688 13.6302 33.4688 12.75H31.875ZM19.125 12.75H17.5312C17.5312 13.6302 18.2448 14.3438 19.125 14.3438V12.75ZM30.748 24.373L21.8229 33.2981L24.0768 35.552L33.0019 26.627L30.748 24.373ZM17.998 31.727L21.8229 35.552L24.0768 33.2982L20.252 29.4731L17.998 31.727ZM38.7812 9.5625V44.625H41.9688V9.5625H38.7812ZM40.375 43.0312H10.625V46.2188H40.375V43.0312ZM12.2188 44.625V9.5625H9.03125V44.625H12.2188ZM10.625 11.1562H19.125V7.96875H10.625V11.1562ZM40.375 7.96875H31.875V11.1562H40.375V7.96875ZM19.125 7.96875H31.875V4.78125H19.125V7.96875ZM31.875 11.1562H19.125V14.3438H31.875V11.1562ZM20.7188 12.75V9.5625H17.5312V12.75H20.7188ZM20.7188 9.5625V6.375H17.5312V9.5625H20.7188ZM30.2812 6.375V9.5625H33.4688V6.375H30.2812ZM30.2812 9.5625V12.75H33.4688V9.5625H30.2812Z"
                    fill="#007AC4"
                  />
                </svg>
              </span>
            </template>
          </NovaDeclaracaoBtn>
        </div>
      </div>
    </div>

    <div class="row" v-if="showDeclarationTable">
      <div class="col-12">
        <TableSort
          :showEmptyrow="false"
          :totalItems="itemsLength"
          defaultSort="Entity"
          :checkBox="false"
          :loading="false"
          :tableModel="tableModel"
          :actions="false"
          :searchFunc="searchFunc"
          :sortFunc="sortFunc"
        >
          <tr
            @click="chooseDeclaration(item)"
            v-for="(item, index) in items"
            :key="index"
            class="btnAlike"
          >
            <td>{{ item.entity }}</td>
            <td>{{ item.role }}</td>
            <td>
              {{ selectedLang.declarationNatureTypeEnum[item.natureTypeId] }}
            </td>
            <td>{{ $filters.formatDate(item.creationDate) }}</td>
          </tr>
          <tr @click="showGetLastSubmitedQuestionBtn" class="btnAlike">
            <td colspan="1000">
              {{ selectedLang.novaDeclaracaoCessacaoText }}
            </td>
          </tr>
        </TableSort>
      </div>
    </div>

    <div class="row mb-5" v-if="showGetLastQuention">
      <div class="col-12">
        <label class="form-label">
          {{ selectedLang.getLastQuention }}
        </label>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="true"
          :txt="selectedLang.yes"
          group="getLastQuention"
          btnId="getLastQuentionYes"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.2521 20.252C37.8745 19.6296 37.8745 18.6204 37.2521 17.998C36.6297 17.3756 35.6206 17.3756 34.9982 17.998L37.2521 20.252ZM21.2501 34L20.1231 35.127C20.7455 35.7494 21.7546 35.7494 22.377 35.127L21.2501 34ZM16.0019 26.498C15.3795 25.8756 14.3704 25.8757 13.748 26.4981C13.1256 27.1205 13.1257 28.1296 13.7481 28.752L16.0019 26.498ZM34.9982 17.998L20.1231 32.8731L22.377 35.127L37.2521 20.252L34.9982 17.998ZM13.7481 28.752L20.1231 35.127L22.377 32.8731L16.0019 26.498L13.7481 28.752ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
      <div class="col-6">
        <NovaDeclaracaoBtn
          :value="false"
          :txt="selectedLang.no"
          group="getLastQuention"
          btnId="getLastQuentionNo"
        >
          <template #icon>
            <span>
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.1269 18.1269C35.7493 17.5046 35.7493 16.4954 35.1269 15.873C34.5045 15.2506 33.4954 15.2506 32.873 15.873L35.1269 18.1269ZM15.873 32.873C15.2506 33.4954 15.2506 34.5046 15.873 35.1269C16.4954 35.7493 17.5046 35.7493 18.127 35.1269L15.873 32.873ZM18.127 15.873C17.5046 15.2506 16.4955 15.2506 15.8731 15.873C15.2507 16.4954 15.2507 17.5046 15.8731 18.1269L18.127 15.873ZM32.873 35.1269C33.4954 35.7493 34.5045 35.7493 35.1269 35.1269C35.7493 34.5046 35.7493 33.4954 35.1269 32.873L32.873 35.1269ZM32.873 15.873L15.873 32.873L18.127 35.1269L35.1269 18.1269L32.873 15.873ZM15.8731 18.1269L32.873 35.1269L35.1269 32.873L18.127 15.873L15.8731 18.1269ZM43.0312 25.5C43.0312 35.1822 35.1822 43.0312 25.5 43.0312V46.2187C36.9426 46.2187 46.2187 36.9426 46.2187 25.5H43.0312ZM25.5 43.0312C15.8178 43.0312 7.96875 35.1822 7.96875 25.5H4.78125C4.78125 36.9426 14.0573 46.2187 25.5 46.2187V43.0312ZM7.96875 25.5C7.96875 15.8178 15.8178 7.96875 25.5 7.96875V4.78125C14.0573 4.78125 4.78125 14.0573 4.78125 25.5H7.96875ZM25.5 7.96875C35.1822 7.96875 43.0312 15.8178 43.0312 25.5H46.2187C46.2187 14.0573 36.9426 4.78125 25.5 4.78125V7.96875Z"
                  fill="#007AC4"
                />
              </svg>
            </span>
          </template>
        </NovaDeclaracaoBtn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { declarationService, userService } from "@/services";
import { utils, declaracaoUnicaHelper, authentication } from "@/helpers";
import {
  ref,
  onMounted,
  watch,
  getCurrentInstance,
  computed,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const { appContext } = getCurrentInstance();
const selectedLang = appContext.config.globalProperties.selectedLang;
const emitter = appContext.config.globalProperties.emitter;

var question1 = ref(null);
var question2 = ref(null);
var question3 = ref(null);
var question4 = ref(null);
var showDeclarationTable = ref(false);
var newDeclarationNatureType = ref(null);
var model = ref(null);
var auth = ref(null);

var tableModelAux = ref({
  total: 0,
  pages: 0,
  sortBy: "Entity asc",
});

var items = ref([]);
var testData = ref([]);
var loading = ref(false);
var showGetLastQuention = ref(false);

watch(testData, (newValue) => {
  emitter.emit("Table-IsSelectAll", newValue.length == items.value.length);
});

const showQuestion2 = computed({
  get() {
    return question1.value != null && !question1.value;
  },
});

const showQuestion3 = computed({
  get() {
    return showQuestion2.value && question2.value != null && !question2.value;
  },
});

const showQuestion4 = computed({
  get() {
    return showQuestion3.value && question3.value != null && !question3.value;
  },
});

const showLastBtn = computed({
  get() {
    return (
      question4.value != null ||
      question1.value ||
      question2.value ||
      question3.value
    );
  },
});

const declarationType = computed({
  get() {
    if (question1.value) {
      return 1;
    } else if (question2.value || question3.value) {
      return 2;
    } else if (question4.value != null && question4.value) {
      return 4;
    } else if (question4.value != null && !question4.value) {
      return 8;
    } else {
      return 1;
    }
  },
});

const tableModel = computed({
  get() {
    return {
      headers: [
        {
          title: selectedLang.entity,
          sort: "Entity",
        },
        {
          title: selectedLang.role,
          sort: "Role",
        },
        {
          title: selectedLang.nature,
          sort: "NatureType",
        },
        {
          title: selectedLang.deliverDate,
          sort: "CreationDate",
        },
      ],
      total: tableModelAux.value.total,
      pages: tableModelAux.value.pages,
    };
  },
});

const itemsLength = computed({
  get() {
    return items.value == null ? 0 : items.value.length;
  },
});

onMounted(() => {
  auth.value = authentication.get();
  model.value = {
    userFOId: auth.value.id,
    Nature: [],
    State: [2, 4, 8],
  };

  store.state.declaracao.estado = 1;
  store.state.declaracao.natureza = declarationType.value;

  emitter.on("selectNovaDeclaracaoBtn", (emitterItem) => {
    selectNovaDeclaracaoBtn(emitterItem);
  });
});

onBeforeUnmount(() => {
  emitter.off("selectNovaDeclaracaoBtn");
});

//Cria uma nova declaração
async function createNewDeclaration() {
  switch (declarationType.value) {
    case 1: //Inicial
      newDeclarationNatureType.value = 1;
      showGetLastQuention.value = true;
      break;
    case 2: //Alteração
      newDeclarationNatureType.value = 2;
      showDeclarationTable.value = true;
      model.value.Nature = [1, 2, 16];
      model.value.State = [8];
      break;
    case 4: //Cessação
      newDeclarationNatureType.value = 4;
      showDeclarationTable.value = true;
      model.value.Nature = [1, 2];
      model.value.State = [8, 64];
      break;
    case 8: //Final
      newDeclarationNatureType.value = 8;
      showDeclarationTable.value = true;
      model.value.Nature = [4];
      model.value.State = [8, 64];
      break;
  }

  if (showDeclarationTable.value) {
    await search(1, tableModelAux.value.sortBy);

    // if (items.value.length == 0) {
    //   showGetLastSubmitedQuestionBtn();
    // }
  }
}

function showGetLastSubmitedQuestionBtn() {
  showGetLastQuention.value = true;
}

async function novaDeclaracaoInicial(getLastSubmitedFlag) {
  if (getLastSubmitedFlag) {
    await getLastSubmited();
  } else {
    store.state.declaracao = declaracaoUnicaHelper.newStoreDeclaration();
  }
  await getPersonalData();
  await createDeclaracaDraft(!getLastSubmitedFlag);
}

async function getLastSubmited() {
  loading.value = true;

  const response = await declarationService.getLastSubmited();

  if (response.code == 0) {
    declaracaoUnicaHelper.responseToStore(response, 0);
  } else {
    store.state.declaracao = declaracaoUnicaHelper.newStoreDeclaration();
    let newAttachments = [];
    store.state.declaracao.attachments = newAttachments;
  }

  const guids = declaracaoUnicaHelper.getAllGuidToClear();

  guids.forEach((guid) => {
    declaracaoUnicaHelper.updateItemValue(
      store.state.declaracao.json.value,
      guid,
      null
    );
  });

  declaracaoUnicaHelper.clearTablesNothingToDeclareEmptyRow();

  loading.value = false;
}

async function createDeclaracaDraft(isNew = false) {
  loading.value = true;

  store.state.declaracao.natureza = declarationType.value;
  if (declarationType.value == 1 || showGetLastQuention.value) {
    store.state.declaracao.RelatedDeclarationId = null;
  }
  store.state.declaracao.estado = 1;

  // let newAttachments = [];
  // store.state.declaracao.attachments = newAttachments;

  let model = declaracaoUnicaHelper.storeToModel();

  if (isNew) {
    model.attachments = [];
    store.state.declaracao.attachments = [];
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "dab5b2ca-921a-4c8a-950a-5413e46625aa",
      []
    );
  }

  declaracaoUnicaHelper.updateVersion(model);
  const response = await declarationService.createdraft(model);

  utils.validateResponse(response, null, () => {
    store.state.declaracao.draftId = response.data;
    router.push({
      name: "DeclaracaoUnicaPage",
      params: { declarationType: declarationType },
    });
  });

  loading.value = false;
}

async function selectNovaDeclaracaoBtn(emitterItem) {
  if (!loading.value) {
    showDeclarationTable.value = false;

    switch (emitterItem.group) {
      case "question1":
        question1.value = emitterItem.value;
        if (!emitterItem.value) {
          showGetLastQuention.value = false;
        }
        question2.value = null;
        question3.value = null;
        question4.value = null;
        break;
      case "question2":
        question2.value = emitterItem.value;
        question3.value = null;
        question4.value = null;
        showGetLastQuention.value = false;
        break;
      case "question3":
        question3.value = emitterItem.value;
        question4.value = null;
        showGetLastQuention.value = false;
        break;
      case "question4":
        showGetLastQuention.value;
        question4.value = emitterItem.value;
        showGetLastQuention.value = false;
        break;
      case "getLastQuention":
        await novaDeclaracaoInicial(emitterItem.value);
        break;
      default:
        break;
    }
  }
}

async function getPersonalData() {
  loading.value = true;

  const response = await userService.getpersonaldata(auth.value.id);

  utils.validateResponse(response, null, () => {
    store.state.declaracao.nif = response.data.nif;
    store.state.declaracao.nic = response.data.nic;
    store.state.declaracao.dateBirth = response.data.dateBirth;
    store.state.declaracao.name = response.data.name;
    store.state.declaracao.naturalidade = response.data.nationality;
    store.state.declaracao.morada = response.data.address;
    store.state.declaracao.codigoPostal = response.data.zipCode;
    store.state.declaracao.localidade = response.data.locale;
    store.state.declaracao.freguesia = response.data.parish;
    store.state.declaracao.concelho = response.data.county;
    store.state.declaracao.email = response.data.email;
    store.state.declaracao.telemovel = response.data.phone;

    store.state.declaracao.NIF = response.data.nif;

    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "d155af2b-21e9-475f-be81-8e42bc5a5641",
      response.data.nif
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "97ac10fd-3e8e-4fa8-a7f2-991bb3d13a0e",
      response.data.nic
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "25fa81a1-70d2-4fca-8292-4717ba2e8cdb",
      response.data.dateBirth
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "c0a2f813-fb84-4923-b7a1-55cef0c5fc1b",
      response.data.name
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "a5bf96eb-8af0-4c05-bb76-a15f57f8490d",
      response.data.nationality
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "37b963d3-b73a-4da7-9a78-cb417f92214a",
      response.data.address
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "38cffadd-0893-47ef-b261-84007c381639",
      response.data.zipCode
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "308ccc8a-452a-4ed7-94e5-5ca17a1667fe",
      response.data.locale
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "77902677-0143-437c-ae9b-b3e54642a080",
      response.data.parish
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "2b75bfd8-392e-49d7-866f-1353a5e765c3",
      response.data.county
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "d3fd512b-65e8-4550-90d9-018a5ef91c0d",
      response.data.email
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "8b022aca-fdf8-4391-8d49-73abc4e63128",
      response.data.phone
    );
    declaracaoUnicaHelper.setFieldValueByKey(
      store.state.declaracao.json.value,
      "54854c64-5845-40bb-a536-8c03da55420a",
      response.data.gender
    );
  });

  loading.value = false;
}

async function searchFunc(page) {
  search(page, tableModelAux.value.sortBy);
}

async function sortFunc(sort) {
  tableModelAux.value.sortBy = sort;
  search(1, sort, model.value);
}

async function search(pageNumber, sortBy) {
  loading.value = true;

  const response = await declarationService.search(
    pageNumber,
    sortBy,
    model.value
  );

  utils.validateResponse(response, null, () => {
    items.value = response.data.items;
    tableModelAux.value.total = response.data.total;
    tableModelAux.value.pages = response.data.pageCount;
  });

  loading.value = false;
}

async function chooseDeclaration(item) {
  loading.value = true;

  let isDraft = item.stateTypeId == 1;

  model.value = {
    Id: item.id,
    isDraft: isDraft,
  };

  const response = await declarationService.get(model.value);

  utils.validateResponse(response, null, async () => {
    declaracaoUnicaHelper.responseToStore(response, item.id);
    store.state.declaracao.draftId = item.id;
    store.state.declaracao.RelatedDeclarationId = item.id;
    store.state.declaracao.estado = 1;
    store.state.declaracao.natureza = declarationType.value;

    declaracaoUnicaHelper.clearTablesNothingToDeclareEmptyRow();
    await createDeclaracaDraft();
  });

  loading.value = false;
}
</script>
