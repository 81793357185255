<template>
  <CustomModal
    modalId="DeclaracaoUnicaSubmitModal"
    size="xl"
    :title="selectedLang.submitDeclaration"
  >
    <template #customModalWarning>
      <div class="p-2">
        <svg
          width="78"
          height="65"
          viewBox="0 0 78 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="38.6487" cy="32.2072" r="22.2523" fill="#D7EBF7" />
          <path
            d="M62.5718 32.2072C62.5718 18.9946 51.8609 8.2836 38.6482 8.2836C25.4356 8.2836 14.7246 18.9946 14.7246 32.2072C14.7246 45.4199 25.4356 56.1308 38.6482 56.1308C51.8609 56.1308 62.5718 45.4199 62.5718 32.2072Z"
            stroke="#007AC4"
            stroke-width="3.8795"
          />
          <line
            x1="39.2339"
            y1="30.4504"
            x2="39.2339"
            y2="41.5766"
            stroke="#007AC4"
            stroke-width="4.68468"
            stroke-linecap="round"
          />
          <path
            d="M39.2344 22.8378L39.2344 24.009"
            stroke="#007AC4"
            stroke-width="4.68468"
            stroke-linecap="round"
          />
        </svg>

        {{ selectedLang.seclaracaoUnicaSubmitModalWarning }}
      </div>
    </template>

    <div class="row mt-5">
      <div class="col-12">
        <h1 class="mb-1">
          {{ selectedLang.sectionList }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered">
          <tbody>
            <tr v-if="!hasInformation">
              <td>
                {{ selectedLang.noSectionFill }}
              </td>
            </tr>
            <tr v-if="showRow('da9c415a-e950-4ce9-ab58-5ce1c1b5af53')">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.incomeHeritage }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('da9c415a-e950-4ce9-ab58-5ce1c1b5af53')">
              <td>
                {{ selectedLang.workDependent }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showAtivoPatrimonialPortugal">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.assets }} - {{ selectedLang.portugal }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('112c6561-4a51-4f79-b637-bbd8d2c0c74c')">
              <td>
                {{ selectedLang.realEstate }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('70fdd906-58d5-4682-967d-90ae90805436')">
              <td>
                {{ selectedLang.quotasShares }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('53168cfb-433e-41e6-9b23-33c1eac7fa6f')">
              <td>
                {{ selectedLang.vehicleRights }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('1a5d2580-aaf3-4874-9e20-68ce10671263')">
              <td>
                {{ selectedLang.securitiesPortfolios }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('e498015f-062e-4fcd-93a8-c5f1393f568b')">
              <td>
                {{ selectedLang.amountsDepositedAccounts }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('7bf6ccdd-f797-4339-8c76-18a248882016')">
              <td>
                {{ selectedLang.financialInvestments }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('873b46af-f3a5-4329-9aa0-a04fd1114467')">
              <td>
                {{ selectedLang.currentBankAccountsTitle }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('58adafd6-226e-4978-a48c-66f53affd3ac')">
              <td>
                {{ selectedLang.creditRights }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('8a7cfdae-1d47-41f9-a366-5ba020c1b90f')">
              <td>
                {{ selectedLang.otherElementsEquityAsset }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showAtivoPatrimonialEstrangeiro">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.assets }} - {{ selectedLang.abroad }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('252ba9e4-bbba-4660-be9e-470b263c4832')">
              <td>{{ selectedLang.realEstate }}</td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('cdeffda1-efd5-44c7-b256-1822dd5bafe0')">
              <td>
                {{ selectedLang.quotasShares }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('05d989a9-7824-459d-aeb3-cc13f1f70c00')">
              <td>
                {{ selectedLang.vehicleRights }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('4a8f53d5-34c2-4bda-b3ea-ec2b157efe67')">
              <td>
                {{ selectedLang.securitiesPortfolios }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('9d7b601c-dc39-4460-a3ab-3bc1178cbae7')">
              <td>
                {{ selectedLang.amountsDepositedAccounts }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('5eea74fb-2cdc-4897-8f04-ce31341f7296')">
              <td>
                {{ selectedLang.financialInvestments }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('151ea0ca-b4a5-4764-a461-67d0c300c915')">
              <td>
                {{ selectedLang.currentBankAccountsTitle }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('f4838f1a-b32e-43bd-9f3e-6de9ff72526d')">
              <td>
                {{ selectedLang.creditRights }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showAtivoPassivoPortugal">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.passive }} - {{ selectedLang.portugal }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('27bb57dd-0fd8-4595-aaca-53bf1634ca87')">
              <td>{{ selectedLang.passive }}</td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('ffd1a4f7-c19e-4e75-b722-11c6e3fae511')">
              <td>
                {{ selectedLang.propertyGuarantees }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showAtivoPassivoEstrangeiro">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.passive }} - {{ selectedLang.abroad }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('50117239-0a0b-427d-9c23-f33c766b2b3a')">
              <td>{{ selectedLang.passive }}</td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('3737df7d-c0e0-4a24-8bce-346a479971f5')">
              <td>
                {{ selectedLang.propertyGuarantees }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('60c13abc-b075-475c-86c5-fa69a7cae259')">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.promiseEquityAdvantage }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('60c13abc-b075-475c-86c5-fa69a7cae259')">
              <td>
                {{ selectedLang.equity }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRow('51240498-fcab-4342-8522-2bf14c84ed39')">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.otherStatements }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('51240498-fcab-4342-8522-2bf14c84ed39')">
              <td>
                {{ selectedLang.otherStatements }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>

            <tr v-if="showRegistoInteresses">
              <td colspan="2">
                <h1 class="mb-1">
                  {{ selectedLang.registrationInterests }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('2c6d9562-dea5-488b-9afe-42519c636daf')">
              <td>
                {{ selectedLang.dataRelatedToActivities }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('cd0e811a-d9bb-49ee-b0f0-1dae0680eb22')">
              <td>
                {{ selectedLang.membershipData }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('62fb0107-a68d-4ba2-822b-bc8adc54369b')">
              <td>
                {{ selectedLang.supportBenefits }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('b1f8c095-fe0c-4bb3-b493-902dc0c31232')">
              <td>
                {{ selectedLang.servicesProvided }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('9b58c917-b14c-4eed-9b3c-40c3d99d45da')">
              <td>
                {{ selectedLang.societies }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
            <tr v-if="showRow('fd85f91f-2803-41bc-b315-ede3a4a66d4e')">
              <td>
                {{ selectedLang.otherSituations }}
              </td>
              <td>
                <h1 class="mb-1">
                  {{ selectedLang.nothingDeclare }}
                </h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 d-f jc-right">
        <button
          type="button"
          class="btn btnS btnPrimary mr-1"
          @click="save"
          :disabled="loading"
        >
          {{ selectedLang.submit }}
        </button>
        <button
          type="button"
          @click="cancel"
          class="btn btnS btnSecundary mr-1"
        >
          {{ selectedLang.cancel }}
        </button>
      </div>
    </div>
  </CustomModal>
</template>

<script>
import { declaracaoUnicaHelper, utils } from "@/helpers";
import declarationService from "@/services/declaration.service";

export default {
  props: {
    declaracaoUnicaProp: [Object, Array],
  },
  data() {
    return {
      hasInformation: false,
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;

      declaracaoUnicaHelper.nothingToDeclareEmptyRows(this.declaracaoUnicaProp);

      let model = declaracaoUnicaHelper.storeToModel();

      const response = await declarationService.updatedraft(model);

      if (response.code == 0) {
        await this.submit();
      } else {
        utils.errorToast(this.selectedLang.submitDeclarationError);
        declaracaoUnicaHelper.clearTablesNothingToDeclareEmptyRow();
        model = declaracaoUnicaHelper.storeToModel();
        await declarationService.updatedraft(model);
      }
      this.emitter.emit("closeCustomModal", "DeclaracaoUnicaSubmitModal");

      this.loading = false;
    },

    async submit() {
      let model = {
        DraftId: this.declaracao.draftId,
      };
      const response = await declarationService.submit(model);

      if (response.code == 0) {
        this.cancel();
        utils.validateResponse(
          response,
          this.selectedLang.submitDeclarationSucssess,
          () => {
            this.$router.push({ name: "ConsultarDeclaracoes" });
          }
        );
      } else {
        declaracaoUnicaHelper.clearTablesNothingToDeclareEmptyRow();
        let declaracao = declaracaoUnicaHelper.storeToModel();
        await declarationService.updatedraft(declaracao);

        switch (response.code) {
          case -101:
            utils.errorToast(this.selectedLang.declarationAlreadyExists);
            break;
          case -110:
            utils.errorToast(this.selectedLang.declarationInitialWrongDates);
            break;
          case -111:
            utils.errorToast(this.selectedLang.declarationCessationWrongDates);
            break;
          case -112:
            utils.errorToast(this.selectedLang.declarationFinalWrongDates);
            break;

          default:
            utils.errorToast(this.selectedLang.submitDeclarationError);
            break;
        }
      }
    },
    cancel() {
      this.emitter.emit("closeCustomModal", "DeclaracaoUnicaSubmitModal");
    },
    showRow(key) {
      let value = this.declaracaoUnicaHelper.getFieldByKey(
        this.declaracaoUnicaProp,
        key
      );

      let result = null;

      if (Array.isArray(value.value)) {
        result = !value.value.length;
      } else {
        result = !value.value;
      }

      if (result) this.hasInformation = true;

      return result;
    },

    // showTitleRow(keys) {
    //   keys.forEach((key) => {
    //     if (this.showRow(key)) {
    //       return true;
    //     }
    //   });
    //   return false;
    // },
  },
  computed: {
    showAtivoPatrimonialPortugal() {
      let result = false;
      let keyArray = [
        "112c6561-4a51-4f79-b637-bbd8d2c0c74c",
        "70fdd906-58d5-4682-967d-90ae90805436",
        "53168cfb-433e-41e6-9b23-33c1eac7fa6f",
        "1a5d2580-aaf3-4874-9e20-68ce10671263",
        "e498015f-062e-4fcd-93a8-c5f1393f568b",
        "7bf6ccdd-f797-4339-8c76-18a248882016",
        "873b46af-f3a5-4329-9aa0-a04fd1114467",
        "58adafd6-226e-4978-a48c-66f53affd3ac",
        "8a7cfdae-1d47-41f9-a366-5ba020c1b90f",
      ];

      keyArray.forEach((key) => {
        if (this.showRow(key)) {
          result = true;
        }
      });
      return result;
    },
    showAtivoPatrimonialEstrangeiro() {
      let result = false;
      let keyArray = [
        "252ba9e4-bbba-4660-be9e-470b263c4832",
        "cdeffda1-efd5-44c7-b256-1822dd5bafe0",
        "05d989a9-7824-459d-aeb3-cc13f1f70c00",
        "4a8f53d5-34c2-4bda-b3ea-ec2b157efe67",
        "9d7b601c-dc39-4460-a3ab-3bc1178cbae7",
        "5eea74fb-2cdc-4897-8f04-ce31341f7296",
        "151ea0ca-b4a5-4764-a461-67d0c300c915",
        "f4838f1a-b32e-43bd-9f3e-6de9ff72526d",
        "ea846529-712c-4b47-953f-5b39015c4f17",
      ];

      keyArray.forEach((key) => {
        if (this.showRow(key)) {
          result = true;
        }
      });
      return result;
    },
    showAtivoPassivoPortugal() {
      let result = false;
      let keyArray = [
        "27bb57dd-0fd8-4595-aaca-53bf1634ca87",
        "ffd1a4f7-c19e-4e75-b722-11c6e3fae511",
      ];

      keyArray.forEach((key) => {
        if (this.showRow(key)) {
          result = true;
        }
      });
      return result;
    },
    showAtivoPassivoEstrangeiro() {
      let result = false;
      let keyArray = [
        "50117239-0a0b-427d-9c23-f33c766b2b3a",
        "3737df7d-c0e0-4a24-8bce-346a479971f5",
      ];

      keyArray.forEach((key) => {
        if (this.showRow(key)) {
          result = true;
        }
      });
      return result;
    },
    showRegistoInteresses() {
      let result = false;
      let keyArray = [
        "2c6d9562-dea5-488b-9afe-42519c636daf",
        "cd0e811a-d9bb-49ee-b0f0-1dae0680eb22",
        "62fb0107-a68d-4ba2-822b-bc8adc54369b",
        "b1f8c095-fe0c-4bb3-b493-902dc0c31232",
        "9b58c917-b14c-4eed-9b3c-40c3d99d45da",
        "fd85f91f-2803-41bc-b315-ede3a4a66d4e",
      ];

      keyArray.forEach((key) => {
        if (this.showRow(key)) {
          result = true;
        }
      });
      return result;
    },
  },
};
</script>
